import React from 'react'
import { Container, Row } from 'react-bootstrap'
import './aboutheader.css'



const NofFoundHeader = () => {
  return (
    <>

      {/* About header section start */}
      <header className='about-header'>
 
        <Container fluid className='about-subheader' style={{"text-align":"center"}}>
          <Row>

          <h1 style={{"color":"white","font-size":"8rem","font-weight":"bolder"}}>404</h1>
       
            <p className='about-para aboutdesktopviewpara'>The page you are looking for does not exist!
            </p>
            <a class="" href="/"><button class="contact-btn mt-50 ms-3">Home</button></a>
          </Row>
        </Container>
      </header>
      {/* About header section ends */}

    </>
  )
}

export default NofFoundHeader