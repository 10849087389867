import React from 'react'
import { Row, Container } from 'react-bootstrap';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './membercom.css';
import down from '../images/down.webp';
import member1 from '../images/members/member1.webp';
import member2 from '../images/members/member2.webp';
import member3 from '../images/members/member3.webp';
import member4 from '../images/members/member4.webp';

import member5 from '../images/members/member5.webp';
import member6 from '../images/members/member6.webp';
// import member7 from '../images/members/member7.webp';
import member7 from '../images/members/polydice.webp';
import member8 from '../images/members/member8.webp';

import member9 from '../images/members/member9.webp';
import member10 from '../images/members/member10.webp';
import member11 from '../images/members/member11.webp';
import member12 from '../images/members/member12.webp';

import member13 from '../images/members/member13.webp';
import member14 from '../images/members/member14.webp';
import member15 from '../images/members/member15.webp';
import member16 from '../images/members/member16.webp';

import member17 from '../images/members/member17.webp';
import member18 from '../images/members/member18.webp';
import member19 from '../images/members/member19.webp';
import member20 from '../images/members/member20.webp';

import member21 from '../images/members/member21.webp';

import { NavLink } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);


const MembersComp = ({setBlank}) => {
    
    const scrollToTop = () => {
        setBlank(true);
        setTimeout(() => {
            ScrollTrigger.refresh();
            window.scrollTo(0, 0);
        }, 100);
        setTimeout(() => {
        setBlank(false);            
        }, 700);
      }

    return (
        <>
            <section className='members-section' >
                {/* Members section starts */}
                <Container fluid className='px-5p memedesktop-view'>
                    <Row>
                        <div className='col-md-10 mx-auto '>
                            <div className='d-flex member-images p-5'>
                                <img src={member1} alt='' />
                                <img src={member2} alt='' />
                                <img src={member3} alt='' />
                                <img src={member4} alt='' />
                            </div>
                        </div>

                        <div className='col-md-10 mx-auto '>
                            <div className='d-flex member-images p-5'>
                                <img src={member5} alt='' />
                                <img src={member6} alt='' />
                                <img src={member7} alt='' />
                                <img src={member8} alt='' />
                            </div>
                        </div>

                        <div className='col-md-10 mx-auto '>
                            <div className='d-flex member-images p-5'>
                                <img src={member9} alt='' />
                                <img src={member10} alt='' />
                                <img src={member11} alt='' />
                                <img src={member12} alt='' />
                            </div>
                        </div>


                        <div className='col-md-10 mx-auto '>
                            <div className='d-flex member-images p-5'>
                                <img src={member13} alt='' />
                                <img src={member14} alt='' />
                                <img src={member15} alt='' />
                                <img src={member16} alt='' />
                            </div>
                        </div>

                        
                        <div className='col-md-10 mx-auto '>
                            <div className='d-flex member-images p-5'>
                                <img src={member17} alt='' />
                                <img src={member18} alt='' />
                                <img src={member19} alt='' />
                                <img src={member20} alt='' />
                            </div>
                        </div>

                        <div className='col-md-10 mx-auto '>
                            <div className='d-flex member-images p-5 border-0'>
                                <img src={member21} alt='' />
                            </div>
                        </div>

                    </Row>
                </Container>
                <Container fluid className='memmobile-view'>
                    <Row>
                        <div className='col-md-10 mx-auto'>
                            <div className='d-flex member-images border-bottom'>
                                <img src={member1} alt='' />
                                <img src={member2} alt='' />
                                <img src={member3} alt='' />
                                <img src={member4} alt='' />
                                <img src={member5} alt='' />
                            </div>
                        </div>
                        <div className='col-md-10 mx-auto'>
                            <div className='d-flex member-images border-bottom'>
                                <img src={member6} alt='' />
                                <img src={member7} alt='' />
                                <img src={member8} alt='' />
                                <img src={member9} alt='' />
                                <img src={member10} alt='' />
                            </div>
                        </div>
                        <div className='col-md-10 mx-auto'>
                            <div className='d-flex member-images border-bottom'>
                                <img src={member11} alt='' />
                                <img src={member12} alt='' />
                                <img src={member13} alt='' />
                                <img src={member14} alt='' />
                                <img src={member15} alt='' />
                            </div>
                        </div>
                        <div className='col-md-10 mx-auto'>
                            <div className='d-flex member-images border-bottom'>
                                <img src={member16} alt='' />
                                <img src={member17} alt='' />
                                <img src={member18} alt='' />
                                <img src={member19} alt='' />
                                <img src={member20} alt='' />
                            </div>
                        </div>
                        </Row>
                        <Row>                        
                        <div className='col-md-10 mx-auto mb-5'>
                            <div className='d-flex w-100 member-images'>
                                <img style={{marginLeft:"10px"}} src={member21} alt='' />
                            </div>
                        </div>


                    </Row>
                </Container>
                {/* Members section ends */}

                {/* want to know more mobile section start */}
                <Container className='mobileview-footer mt-3 pt-3 mb-5 pb-3'>
                <Row>
                    <div className='mx-auto text-center mt-5'>
                        <div className='footer-heading'>
                            <h3>WANT  T<span><img src={down} alt='' style={{ transform: "rotate(180deg) translateY(50%)" }} /></span></h3>
                            <h3>KN<span><img src={down} alt='' style={{ transform: "rotate(180deg) translateY(50%)" }} /></span>W M<span><img src={down} alt='' /></span>RE?</h3>
                        </div>

                        <NavLink to='/apply-to-join' onClick={scrollToTop}><button className="contact-btn mt-50">CONTACT US</button></NavLink>
                    </div>
                </Row>
            </Container>
                {/* want to know more mobile section ends */}

            </section>



        </>
    )
}

export default MembersComp
