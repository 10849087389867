import React from 'react'
import './aboutsection.css'
import { Container, Row, Col } from 'react-bootstrap'

import img2 from '../images/Pubclub-mission-1.webp'
import img3 from '../images/news.webp'
import img4 from '../images/media1.webp'
import img5 from '../images/entertainment.webp'
import img6 from '../images/gaming.webp'
import img7 from '../images/ott.webp'
import img8 from '../images/industry-insights.webp'
import img9 from '../images/local-trends.webp'
import img10 from '../images/creative-initiatives.webp'
import img11 from '../images/research-finding.webp'

import mobileimg from '../images/mobilemissionimg.webp'

import down from '../images/down.webp';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";
import vision from '../images/vision.webp';
import visionmobimg from '../images/mobvision.webp';

import Lottie from "lottie-react";
import weaspire from "../lottie-files/We aspire/data.json";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Aboutsection = ({setBlank}) => {

  const scrollToTop = () => {
    setBlank(true);
    setTimeout(() => {
      ScrollTrigger.refresh();
    window.scrollTo(0, 0);
    }, 100);
    setTimeout(() => {
    setBlank(false);            
    }, 500);
  }

  return (
    <>
    {/* About page Body Start */}
      <section className='about-section'>

        {/* About page Mission Vision Slider Start */}

        <Container className='mb-5 pb-5 desktopview desktop about-container '>

          <Carousel variant="dark">
            <Carousel.Item interval={2000}>
              <Row>
                <Col className='col-md-6 top-margin'>
                  <h3>MISSI<span><img style={{transform:"rotate(180deg) translateY(50%)"}} src={down} alt='' /></span>N</h3>
                  <p className='section-para mt-5'>To offer industry insights through the lens of key decision-makers, while empowering creators to actively uplift the Ad Tech ecosystem.</p>
                </Col>
                <Col className='col-md-6 right-box'>
                  <img src={img2} alt="Mission" />
                </Col>
              </Row>

            </Carousel.Item>
            <Carousel.Item interval={2000}>

              <Row>
                <Col className='col-md-6 top-margin'>

                  <h3>VISI<span><img src={down} alt='' /></span>N</h3>

                  <p className='section-para mt-5'>
                    To identify tech-led growth opportunities and initiatives that are shaping the future
                    of Ad Tech in emerging markets.
                  </p>

                </Col>

                <Col className='col-md-6 right-box'>

                  <img src={vision} alt='Vision' className='visionimg' />

                </Col>
              </Row>
            </Carousel.Item>
          </Carousel>
        </Container>
        {/* About page Mission Vision Slider Desktop Ends */}

        {/* About page Mission Vision Slider mobile Start */}
        <Container className='mobileview mobile mt-5 mission-vision-slider'>
          <Carousel variant="dark">
            <Carousel.Item interval={2000}>
              <Row className='text-center'>
                <div className="mission">
                  <h3>MISSI<span><img  style={{transform:"rotate(180deg) translateY(50%)"}}  src={down} alt='' /></span>N</h3>
                </div>
                <div className='mobileviewimg'>
                  <img src={mobileimg} alt=" "  className='visionimgmobile' />
                </div>
                <p className='section-para mt-5'>To offer industry insights through the <br />  lens of decision-makers and actively <br /> uplift the Ad tech ecosystem.</p>
              </Row>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <Row className='text-center'>
                <div className="vision mission">
                  <h3>VISI<span><img src={down} alt='' /></span>N</h3>
                </div>
                <div className='mobileviewimg'>
                  <img src={visionmobimg} alt='' className='visionimgmobile'/>
                </div>
                <p className='section-para mt-5'>
                To identify Ad Tech growth opportunities <br /> and initiatives in emerging markets that <br /> shape global trends.
                </p>

              </Row>

            </Carousel.Item>

          </Carousel>

        </Container>

        {/* About page Mission Vision Slider mobile Ends */}

        {/* About page Who We are section Start */}
        <Container fluid className='who-we-are-sect mt-5 pt-5'>
          <Row>
            <h1 className='about-sub-heading'>WHO WE ARE?</h1>
            <p className='about-sub-para desktopview desktop pt-4'>We are a peer-powered network for industry <br />leaders and key decision-makers across</p>
            <p className='about-sub-para2 mobileview mobile pt-4'>We are a peer-powered network <br /> for industry leaders across</p>
            <Container className='desktopview desktop'>
              <Row>
                <Col className='col-md-10 mx-auto mt-5 pt-4 pb-5 mb-5'>
                  <div className='list'>
                  <ul className='about-list'>
                    <li><img src={img3} alt="" /><h4>news</h4></li>
                    <li><img src={img4} alt=" " /> <h4>media</h4></li>
                    <li><img src={img5} alt=" " /> <h4>entertainment</h4></li>
                    <li className='pt-3'><img src={img6} alt=" " /> <h4>gaming</h4></li>
                    <li className='pt-3'><img src={img7} alt=" " /> <h4>OTT</h4></li>
                  </ul>
                </div>
                </Col>
              </Row>
            </Container>
             {/* About page Who We are section Desktop Ends */}

             {/* About page Who We are section Mobile Start */}
          <Container className='mobileview mobile pb-5 mt-4'>
                <Row>
                  <Col className='col-md-6 padding-social-icon-left'>
                    <div className='text-center social-icon'>
                      <img src={img3} alt="" /><h4>news</h4>
                    </div>
                  </Col>
                  <Col className='col-md-6'></Col>
                </Row>
                <Row>
                  <Col className='col-md-6'></Col>
                  <Col className='col-md-6 padding-social-icon-right'>
                    <div className='text-center social-icon'>
                      <img src={img4} alt=" " /> <h4>media</h4>
                    </div>
                  </Col>
                </Row>
              <Row>
                <Col className='col-md-6 padding-social-icon-left'>
                  <div className='text-center social-icon'>
                    <img src={img5} alt=" " /> <h4>entertainment</h4>
                  </div>
                </Col>
                <Col className='col-md-6'></Col>
              </Row>
              <Row>
                <Col className='col-md-6'></Col>
                <Col className='col-md-6 padding-social-icon-right'>
                  <div className='text-center social-icon'>
                    <img src={img6} alt=" " /> <h4>gaming</h4>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className='col-md-6 padding-social-icon-left'>
                  <div className='text-center social-icon'>
                    <img src={img7} alt=" " /> <h4>OTT</h4>
                  </div></Col>
                <Col className='col-md-6'></Col>
              </Row>
            </Container>
            {/* About page Who We are section Mobile Ends */}
          </Row>
        </Container>
          {/* About page Who We are Section Ends */}

        {/* About page AT PUBCLUB Section start */}
        <Container className='about-slider'>
          <Row className='mt-3'>
            <h1 className='slider-heading mt-5 pt-3'>AT PUBCLUB, MEMBERS HAVE THE</h1>
            <h1 className='slider-heading'>OPPORTUNITY TO LEARN ABOUT</h1>
            <div className='my-5 pb-4 text-center'>
              <div className='Carouselsection'>
                <Carousel variant="dark">
                  <Carousel.Item interval={1000}>

                    <img
                      className="d-block"
                      src={img8}
                      alt=" "
                    />

                  </Carousel.Item>
                  <Carousel.Item interval={1000}>
                    <img
                      className="d-block"
                      src={img9}
                      alt=" "
                    />

                  </Carousel.Item>
                  <Carousel.Item interval={1000}>
                    <img
                      className="d-block"
                      src={img10}
                      alt=" "
                    />
                  </Carousel.Item>
                  <Carousel.Item interval={1000}>
                    <img
                      className="d-block"
                      src={img11}
                      alt=" "
                    />
                  </Carousel.Item>
                </Carousel>

              </div>
            </div>
            <Container fluid className='desktopview desktop'>
              
              <div className='gif-image mb-4'>
                <Lottie animationData={weaspire} loop={true} />
                </div>


            </Container>
            <Container className='mobileview mobile'>
              <Row>
                <p className='slider-mobileview-para'>We aim to challenge the status quo  <br />and elevate emerging markets in the <br /> Ad Tech space.
                </p>
              </Row>
            </Container>
          </Row>
          {/* carousel container */}

        </Container>

        {/* About page AT PUBCLUB Section Ends */}

        {/* About page Know more Section Mobile Start */}

        <Container className='mobileview-footer mt-4 mb-4'>
          <Row>
            <Col className='mx-auto text-center mt-5'>
              <div className='footer-heading text-center'>
                <h3>WANT  T<span><img src={down} alt='' style={{ transform: "rotate(180deg) translateY(50%)" }} /></span></h3>
                <h3>KN<span><img src={down} alt='' style={{ transform: "rotate(180deg) translateY(50%)" }} /></span>W M<span><img src={down} alt='' /></span>RE?</h3>
              </div>

              <NavLink to='/apply-to-join' onClick={scrollToTop} >  <button className="contact-btn mt-5">CONTACT US</button></NavLink>
            </Col>
          </Row>
        </Container>
        {/* About page Know more Section Mobile Ends */}

      </section>
      {/* About page Body Ends */}

    </>
  )
}

export default Aboutsection