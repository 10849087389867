import React from 'react'
import "./header.css";
import Container from 'react-bootstrap/Container';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Lottie from "lottie-react";
import empowering from "../lottie-files/Empowering the next gen/data.json";


const Header = ({setBlank}) => {

const scrollToTop = () => {
  setBlank(true);
  setTimeout(() => {
  window.scrollTo(0, 0);
  }, 100);
  setTimeout(() => {
      setBlank(false);            
  }, 700);
}

  return (
    <> 
    <Helmet>
      <title>PubClub: Empowering Next Gen of Publishers and Creators</title>
      <meta name='description' content='Join PubClub - a community empowering publishers and creators. Unleash your potential, connect with experts, and thrive in the digital realm.' />
      <link rel="canonical" href="https://www.pubclub.io/" />
    </Helmet>
    {/* Home header section start */}
    <header className='header-home '  > 

    <Container fluid className='hero px-5'>
        
        
        <div className='hero-content'>
            
            <div className='hero-text'>
               
                <Lottie animationData={empowering} loop={true} />
               <NavLink to='/apply-to-join' onClick={scrollToTop} > <button className="applynow-btn mt-50 ms-3">APPLY TO JOIN</button></NavLink>
            </div>
        </div>
    
     </Container>
     </header>
      {/* Home header section start */}
     </>
  )
}

export default Header
