import React from 'react'
import Container from 'react-bootstrap/Container';

// import upcap from '../images/up-cap.webp';
// import downcap from '../images/udown-cap.webp';

import './headerinsights.css'

import bg from '../images/header-insights.webp';
import bgmb from '../images/header-insights-mb.webp';

// import insightgif1 from '../images/gif/discover-latest.gif'

// import insightgif from '../images/gif/discover-the-latest-min.gif'
// import insightgifmb from '../images/gif/Discover-me-mb.gif'


import Lottie from "lottie-react";
import discover from "../lottie-files/Discover the latest/data.json";
import { Helmet } from 'react-helmet-async';


const HeaderInsights = () => {
  return (
    <>
    <Helmet>
      <title>PubClub Insights: Adtech Industry Updates</title>
      <meta name='description' content="Get the latest updates, trends, and expert perspectives with PubClub Insights. Join PubClub for a knowledge-driven journey into the future of advertising" />
      <link rel="canonical" href="https://www.pubclub.io/insights" />
    </Helmet>
    {/* header insights start */}
    <header className='header-insights '  > 
      <div className='bg'>
          <img className='desktop' src={bg} alt='' />
          <img className='mobile' src={bgmb} alt='' />
      </div>
        <Container fluid className='hero px-5'>
            
            <div className='hero-content'>
                               
                <Lottie className='headerinsightgif' animationData={discover} loop={true} />
                              
            </div>

        </Container>
    </header>
    {/* header insights ends */}
    </>
  )
}

export default HeaderInsights