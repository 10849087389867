import React, { useRef, useState, useEffect } from 'react'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { useFormik } from 'formik';
import { Container, Row, Col } from 'react-bootstrap'

import footerlogo from "../images/footer-logo.webp";
import down from "../images/down.webp";
import { NavLink } from "react-router-dom";
import { useParams } from 'react-router-dom';
import * as Yup from "yup";
import "./footer.css";


gsap.registerPlugin(ScrollTrigger);
const initialValues = {
  email: ""
}



const Footer = (props) => {

  let { id } = useParams(); 
  const [res, setRes] = useState();

  const scrollToTop = () => {
    props.setBlank(true);
    setTimeout(() => {
      ScrollTrigger.refresh();
      window.scrollTo(0, 0);
    }, 100);
    setTimeout(() => {
      props.setBlank(false);            
    }, 700);
  }

  const subscribeSchema = Yup.object({
    email: Yup.string().email().min(2).required("Please enter your Email").matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/, "Email must be a valid email "),
  });

  
  const { values, errors, handleBlur, handleChange, handleSubmit } =
  useFormik({
    initialValues,
    validationSchema: subscribeSchema,
    onSubmit: (values, action) => {
      // on form submition
      const formData = new FormData();
      formData.append("email", values.email);

      fetch('https://blogs.pubclub.io/wp-json/mail/subscribe', {
        method: 'POST',
        body:formData,
        })
        .then(response => response.json())
        .then(data => {
        // enter you logic when the fetch is successful
          console.log(data)
          setRes(data);
          setTimeout(()=>{
            setRes("");
          }, 2000);

        })
    
        //  .catch(error => {console.log(error)}) 

      action.resetForm();
    },
  });

  //  console.log(errors);

  const footerRef = useRef(null);

    // footer animation gsap code start
  useEffect(() => {
    gsap.to(".footer .footer-bg", {
        ease: "power1.out",
      top: 0,
      duration: 1,
      delay: 1,
      scrollTrigger: {
        trigger: ".footer",
        start: "top bottom",
        end: '=+30',
        scrub: 0.5,
      }
    });

    gsap.to(".footer .slide-up", {
      ease: "power1.out",
      opacity: 1,
      translateY: 0,
      duration: 1,
      delay: 1,
      scrollTrigger: {
        ease: "none",
        trigger: ".footer .slide-up",
        start: "top 45%",
        end: '+=30',
        scrub: 0.5,
      }
    });



    gsap.to("footer .fill-width", {
      ease: "power1.out",
      width: '100%',
      duration: 1,
      delay: 2,
      scrollTrigger: {
        trigger: "footer .fill-width",
        start: "top 90%",
        end: '+=10',
        scrub: 0.5,
      }
    });

    gsap.to("footer .slide-up2", {
      ease: "power1.out",
      opacity: 1,
      translateY: 0,
      duration: 1,
      delay: 2.5,
      scrollTrigger: {
        trigger: "footer .slide-up2",
        start: "top 90%",
        end: '+=20',
        scrub: true,
      }
    });


    window.onload = function() {
      ScrollTrigger.refresh();
    }

    ScrollTrigger.refresh();
     
  }, [])

  ScrollTrigger.refresh();
    // footer animation gsap code start

  return (
    <>
      <footer className={`footer ${props.class}`} ref={footerRef}>
        <div className='footer-bg desktop'></div>
         {/* footer desktop section ends */}
        <Container fluid className='px-5 desktop'>

          <Row className='pt-10p slide-up'>
            <Col className=' col-md-8 mx-auto text-center'>
              <div className='footer-heading'>
                <h3>WANT  T<span><img src={id !== "" ? down : down } alt='' /></span><br />   KN<span><img src={id !== "" ? down : down } alt='' /></span>W M<span><img style={{ transform: "rotate(180deg) translateY(50%)" }} src={id !== "" ? down : down } alt='' /></span>RE?</h3>
              </div>
              <NavLink to='/apply-to-join' onClick={scrollToTop}><button className="contact-btn mt-50 ms-3">CONTACT US</button></NavLink>
            </Col>
          </Row>

          <Row className='pt-5p pb-5'>
            <Col className='col-md-10 mx-auto'>
              <div className="footer-hr fill-width"></div>

              <Container>
                <Row className="form-row text-center pt-5 align-center justify-content-center">
                  <Col className="col-md-1"></Col>
                  <Col className='col-md-4'>
                    <div className="footer-logo-container slide-up2">
                      <div><img src={id !== "" ? footerlogo : footerlogo } alt='' /></div>
                      <div>
                        <h4>Stay in the know</h4>
                        <h5>SIGN UP FOR OUR NEWSLETTER</h5>
                      </div>
                    </div>
                  </Col>


                  <Col className='col-md-6 mx-5 mt-3'>
                    <form className='d-flex slide-up2' style={{ gap: "30px" }} onSubmit={handleSubmit}>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder='Enter your email'
                        className='newsletter-input'
                        value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                      />
                      
                      <button className='contact-btn newsletter-btn ' type="submit">Sign Up</button>
                    </form>
                    <div className='errormsg'> {errors.email ? (
                        <p className="form-error">{errors.email}</p>
                      ) : null}
                        <p className="form-success mt-3">{res}</p> </div>
                  </Col>
                  <Col className="col-md-1"></Col>
                </Row>

                <Row className='links-row pt-5 pb-3'>
                  <Col className="col-md-1"></Col>
                  <Col className="col-md-10">
                    <ul className='footer-menu align-middle'>
                      <li><NavLink onClick={scrollToTop} to='/about'>ABOUT</NavLink></li>
                      <li><NavLink onClick={scrollToTop} to='/apply-to-join'>APPLY TO JOIN</NavLink></li>
                      <li><NavLink onClick={scrollToTop} to='/terms-and-conditions'>TERMS & CONDITIONS</NavLink></li>
                      <li><NavLink onClick={scrollToTop} to='/privacy-policy'>PRIVACY POLICY</NavLink></li>
                      <li className="px-4">
                        <a href='https://www.linkedin.com/groups/9510100/' target='blank'>LINKEDIN</a></li>
                    </ul>
                  </Col>
                  <Col className="col-md-1"></Col>
                </Row>
              </Container>
 
            </Col>
          </Row>
        </Container>
        {/* footer desktop section ends */}

        {/* footer mobile section start */}

        <Container className="footermobile-view mobile footerbg mt-5 pt-5">

          <Row>
            <Col className="col-md-12 text-center">
              <div>
                <div className="footer-logo-container">
                  <div className="">
                    <img src={id !== "" ? footerlogo : footerlogo } alt='' />
                  </div>
                  <div>
                    <h4>Stay in the know</h4>
                    <h5>SIGN UP FOR OUR NEWSLETTER</h5>
                  </div>
                </div>

              </div>
            </Col>
          </Row>
          <form className='mt-4 mx-auto px-4' onSubmit={handleSubmit}>
            <input
              id="email"
              name="email"
              type="email"
              placeholder='Enter your email'
              className='form-control'
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
          />
          <div className='errormsg'> {errors.email ? (
            <p className="form-error">{errors.email}</p>
          ) : null}
          </div>
            <button className='contact-btn newsletter-btn mt-4' type="submit">Sign Up</button>
            <p className="form-success mt-3">{res}</p>
          </form>
          

          <div className="mt-5  mb-5">
            <ul className='footer-menu px-4'>
              <li><NavLink onClick={scrollToTop} to='/about'>ABOUT</NavLink></li>
              <li><NavLink onClick={scrollToTop} to='/apply-to-join'>APPLY TO JOIN</NavLink></li>
              <li><NavLink onClick={scrollToTop} to='/terms-and-conditions'>TERMS & CONDITIONS</NavLink></li>
              <li><NavLink onClick={scrollToTop} to='/privacy-policy'>PRIVACY POLICY</NavLink></li>
              <li>
                <a href='https://www.linkedin.com/groups/9510100/' target='blank'>LINKEDIN
                </a></li>
              
            </ul>
          </div>
        </Container>
        {/* footer mobile section ends */}

      </footer>

    </>
  )
}

export default Footer
