import React from 'react'
import Menu from '../component/Menu';
import NofFoundHeader from '../component/NofFoundHeader';


const NotFound = ({setBlank}) => {
  return (
    <> 
      <Menu /> 
      <NofFoundHeader/>
    </>
  )
}

export default NotFound